import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as SentryTypes from "@sentry/types";
import pkg from "../../package.json";

export default function configureSentry(dsn, history) {
  dsn &&
    Sentry.init({
      dsn: dsn,
      environment: process.env.REACT_APP_STAGE,
      release: `${pkg.name}@${pkg.version}`,
      dist: process.env.REACT_APP_BOT,
      debug: false,
      sampleRate: 1,
      tracesSampleRate: getTracingSampleRate(),
      attachStacktrace: true,
      logLevel: SentryTypes.LogLevel.Error,
      integrations: [
        new Integrations.BrowserTracing({
          traceFetch: true,
          traceXHR: true,
          tracingOrigins: [getTracingOrigins()],
        }),
      ],
    });

  function getTracingOrigins() {
    return process.env.REACT_APP_SENTRY_DSN && "true" === process.env.REACT_APP_SENTRY_TRACE
      ? process.env.REACT_APP_ENDPOINT
      : /^\//;
  }
  function getTracingSampleRate() {
    return "dev" === process.env.REACT_APP_STAGE ? 1 : 0.25;
  }
}
