import "./styles/index.scss";

import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import multi from "redux-multi";
import thunk from "redux-thunk";

import App from "./App";
import configureSentry from "./config/sentry";
import mainReducer from "./contexts/store";
import * as serviceWorker from "./serviceWorker";


configureSentry(process.env.REACT_APP_SENTRY_DNS)

if (window.MediaRecorder === undefined) {
  const AudioRecorder = require("audio-recorder-polyfill").default;
  window.MediaRecorder = AudioRecorder;
}

const store = applyMiddleware(thunk, multi)(createStore)(mainReducer);
const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById("root")
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration success, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Falha no registro do service worker, erro:", err);
    });

  serviceWorker.register();
}
